<template>
  <div class="column no-padding is-full">
    <div class="card">
      <header class="card-header has-background-primary">
        <div @click.stop="$router.go(-1)" class="card-header-icon">
          <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
        </div>
        <p class="card-header-title has-text-light">UJIAN DETAIL</p>
        <template v-if="canChange">
          <router-link
            :to="{ name: 'ujian-change', params: { id: paramsId } }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="edit" />
          </router-link>
          <a class="card-header-icon" @click.stop.prevent="confirmDelete">
            <b-icon icon="trash" size="is-small" class="has-text-light" />
          </a>
        </template>
      </header>
      <div class="card-content events-card">
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Stase</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ ujian.stase.nama }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Mahasiswa</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ ujian.pspd }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Jenis</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ ujian.jenis }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Judul</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ ujian.judul }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Rencana Ujian</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ ujian.rencana_ujian }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Penguji</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <p v-for="penguji in ujian.penguji_set" :key="penguji.id">{{ penguji.nama }}</p>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Mengulang</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="ujian.mengulang">Ya</template>
            <template v-else>Tidak</template>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Tanggal Maju</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <span v-if="ujian.tanggal_ujian">{{ ujian.tanggal_ujian }}</span>
            <span v-else>
              <i class="fas fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Verifikasi</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <hasil-mark :hasil="ujian.verifikasi"></hasil-mark>
          </div>
        </div>
        <template v-if="!isPSPD">
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Nilai Sikap</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="ujian.extra_data && ujian.extra_data.can_update_nilai">
              <b-field :type="{'is-danger': errorMap.nilai_sikap}" :message="errorMap.nilai_sikap">
                <b-numberinput
                  min="0"
                  max="100"
                  step="0.01"
                  v-model="ujian.nilai_sikap"
                  :controls="false"
                  @input="validateInput('nilai_sikap')"
                ></b-numberinput>
              </b-field>
            </template>
            <template v-else>
              <span v-if="ujian.nilai_sikap !== null">{{ ujian.nilai_sikap }}</span>
              <span v-else class="icon has-text-info">
                <b-icon size="is-small" icon="minus" />
              </span>
            </template>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Nilai</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="ujian.extra_data && ujian.extra_data.can_update_nilai">
              <b-field :type="{'is-danger': errorMap.nilai}" :message="errorMap.nilai">
                <b-numberinput
                  min="0"
                  max="100"
                  step="0.01"
                  v-model="ujian.nilai"
                  :controls="false"
                  @input="validateInput('nilai')"
                ></b-numberinput>
              </b-field>
            </template>
            <template v-else>
              <span v-if="ujian.nilai !== null">{{ ujian.nilai }}</span>
              <span v-else class="icon has-text-info">
                <b-icon size="is-small" icon="minus" />
              </span>
            </template>
          </div>
        </div>
        </template>

        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Hasil</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="ujian.extra_data && ujian.extra_data.can_update_nilai">
              <b-field :type="{'is-danger': errorMap.hasil}" :message="errorMap.hasil">
                <b-select v-model="ujian.hasil" @input="validateInput('hasil')">
                  <option value="Belum">Belum</option>
                  <option value="Lulus">Lulus</option>
                  <option value="Tidak Lulus">Tidak Lulus</option>
                </b-select>
              </b-field>
            </template>
            <template v-else>{{ ujian.hasil }}</template>
          </div>
        </div>
        <div v-if="ujian.extra_data && ujian.extra_data.can_update_nilai" class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold"></div>
          <div class="column detail has-text-weight-semibold is-1"></div>
          <div class="column detail has-text-left">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop.prevent="saveObject"
              :disabled="!isValid"
            >Simpan</b-button>
          </div>
        </div>
        <div v-if="canUploadDok" class="columns is-mobile">
          <div class="column">
            <b-button loading v-if="isUploading">Loading</b-button>
            <b-field v-else class="file">
              <b-upload v-model="ujian.foto" @input="resize" accept=".jpg, .jpeg, .png">
                <a class="button is-primary">
                  <b-icon icon="upload"></b-icon>
                  <span>Upload Foto</span>
                </a>
              </b-upload>
              <span class="file-name" v-if="ujian.foto">{{ ujian.foto.name }}</span>
            </b-field>
          </div>
        </div>

        <ul v-if="hasDokumen" class="box-gallery-container three-cols">
          <li
            v-for="foto in ujian.dokumenujian_set"
            :key="foto.id"
            class="box-gallery show"
            style="transition-delay: 0s;"
          >
            <div class="inner">
              <a v-if="canUploadDok" @click="confirmDeleteFoto(foto)">
                <b-icon icon="times-circle" class="is-pulled-right"></b-icon>
              </a>
              <a :href="foto.url" class="glightbox">
                <img class="lampiran" :src="getWebpPath(foto.url)" />
              </a>
            </div>
          </li>
        </ul>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      </div>
    </div>
  </div>
</template>


<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import GLightbox from "glightbox";
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import Ujian from "../models/detailUjian.js";
import UploadPhoto from "@/apps/core/modules/uploadPhoto.js";
import { webpPath } from "@/apps/core/modules/utils.js";

export default {
  name: "UjianDetail",
  mixins: [createUpdateMixin, deleteMixin],
  props: ["title"],
  components: {
    HasilMark: () => import("@/apps/core/components/HasilMark.vue")
  },
  data() {
    this.ujianMdl = new Ujian();
    this.objectMdl = this.ujianMdl;
    let obv = this.ujianMdl.getObservables();
    obv.paramsId = this.$route.params.id;
    this.uploadPhoto = new UploadPhoto("ujian", this.notify);
    obv.isUploading = false;
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", ["isPreceptor", "isPSPD"]),
    canChange() {
      return (
        this.isLoaded &&
        this.ujian.extra_data.can_change
      );
    },
    hasDokumen() {
      if (!this.isPSPD) {
        return this.ujian.dokumenujian_set.length > 0;
      }
      return false;
    },
    canUploadDok() {
      return !this.isPSPD && this.isLoaded && this.ujian.extra_data.can_upload_dok;
    }
  },
  methods: {
    getWebpPath(url) {
      return webpPath(url);
    },
    notify(uploading) {
      this.isUploading = uploading;
    },
    onLoaded() {
      if (!this.canUploadDok) return;
      this.uploadPhoto.requestAWS();
    },
    onSaved() {},
    resize(foto) {
      this.uploadPhoto.resize(foto, (foto, url) => {
        this.uploadPhoto.upload(foto, () => this.saveFoto(url), null, () => {
          this.onLoaded();
        });
      });
    },
    saveFoto(url) {
      let uploadDocUrl = `${APP_CONFIG.baseAPIURL}/ujian/dokumen/`;
      let data = { ujian: this.ujian.id, url: url };
      axios
        .post(uploadDocUrl, data)
        .then(response => {
          this.ujian.dokumenujian_set.splice(0, 0, {
            id: response.data.id,
            url: response.data.url
          });
          this.ujian.foto = null;
          this.$buefy.toast.open("Foto berhasil di upload.");
        })
        .catch(() => {
          this.$buefy.toast.open("Foto gagal di upload.");
        });
    },
    confirmDeleteFoto(foto) {
      this.$buefy.dialog.confirm({
        title: "Menghapus Data",
        message: "Anda yakin akan <b>menghapus</b> foto ini?",
        confirmText: "Hapus Foto",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          let documenUrl = `dokumen/${foto.id}`;
          this.ujianMdl.delete(documenUrl, () => {
            const idx = this.ujian.dokumenujian_set.indexOf(foto);
            this.ujian.dokumenujian_set.splice(idx, 1);
          });
        }
      });
    }
  },
  watch: {
    ujian: {
      // ujian berubah, maka tombol simpan bisa aktif
      handler(newValue, oldValue) {
        if (!this.ujianMdl.getEdited()) {
          this.ujianMdl.setEdited(!!oldValue.id);
        }
      },
      deep: true
    }
  },
  mounted() {
    this.glightbox = GLightbox();
  },
  updated() {
    this.glightbox.reload();
  }
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";
@import "~glightbox/dist/css/glightbox.min.css";
@import "../../core/assets/css/glightbox.css";
</style>
