import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
// import { webpSupport } from "utils";


function checkWebpFeature(feature, callback) {
  var kTestImages = {
      lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
      lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
      alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
      animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
  };
  var img = new Image();
  img.onload = function () {
      var result = (img.width > 0) && (img.height > 0);
      callback(feature, result);
  };
  img.onerror = function () {
      callback(feature, false);
  };
  img.src = "data:image/webp;base64," + kTestImages[feature];
}

let webpSupport = false;
checkWebpFeature('lossy', function (feature, isSupported) {
  webpSupport = isSupported;
});


class UploadPhoto {

  constructor(dirname, notify) {
    this.s3 = null;
    this.awsDirname = dirname;
    this.notify = notify;
  }

  // canUseWebP(elem) {
  //   if (elem.getContext && elem.getContext('2d')) {
  //       // was able or not to get WebP representation
  //       return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  //   }

  //   // very old browser like IE 8, canvas not supported
  //   return false;
  // }

  showErrorMessage() {
    this.$buefy.toast.open("Foto gagal di upload.");
  }

  resize(foto, callback) {
    const height = APP_CONFIG.resizeHeight;
    const width = APP_CONFIG.resizeWidth;
    const reader = new FileReader();
    const resizeToServer = this.resizeToServer;
    const showErrorMessage = this.showErrorMessage;
    const url = this.s3.url + this.s3.fields.key;
    reader.readAsDataURL(foto);
    reader.onload = event => {
      const img = new Image();
      img.src = event.target.result;
      (img.onload = () => {
        const elem = document.createElement("canvas");
        // if (!this.canUseWebP(elem)) this.upload(foto);
        let imgWidth = img.width;
        let imgHeight = img.height;
        if (imgWidth > width) {
          const scaleWFactor = width / imgWidth;
          imgHeight = imgHeight * scaleWFactor;
          imgWidth = width;
        }
        if (imgHeight > height) {
          const scaleHFactor = height / imgHeight;
          imgWidth = imgWidth * scaleHFactor;
          imgHeight = height;
        }
        elem.height = imgHeight;
        elem.width = imgWidth;
        const ctx = elem.getContext("2d");
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
        if (webpSupport) {
          ctx.canvas.toBlob(
            blob => {
              const newFileName =
                foto.name.substr(0, foto.name.lastIndexOf(".")) +
                ".webp";
              foto = new File([blob], newFileName, {
                type: "image/webp",
                lastModified: Date.now()
              });
              callback(foto, url);
            },
            "image/webp",
            APP_CONFIG.resizeQuality
          );
        } else {
          ctx.canvas.toBlob(
            blob => {
              const newFileName =
                foto.name.substr(0, foto.name.lastIndexOf(".")) +
                ".jpg";
              foto = new File([blob], newFileName, {
                type: "image/jpg",
                lastModified: Date.now()
              });
              resizeToServer(
                foto, (newFoto) => callback(newFoto, url), () => showErrorMessage());
            },
            "image/jpg",
            APP_CONFIG.resizeQuality
          );   
        }
      }),
        (reader.onerror = error => error);
    };
  }

  resizeToServer(foto, uploadFile, showErrorMessage) {
    const formData = new FormData();
    formData.append("img", foto, foto.name);
    axios
      .post(APP_CONFIG.toolsConvertWebpUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        responseType: 'blob'
      })
      .then((response) => {
        var blob = new Blob([response.data]);
        const newImage = new File([blob], "image.webp")
        uploadFile(newImage);
      })
      .catch(() => showErrorMessage())
  }

  upload(foto, success, fail, done) {
    if (foto == null) {
      success(true);
      return;
    }

    if (this.s3 == null) {
      this.showErrorMessage();
      return;
    }

    if (this.notify) this.notify(true);

    const formData = new FormData();
    for (const [key, val] of Object.entries(this.s3.fields)) {
      formData.append(key, val);
    }
    formData.append("file", foto, foto.name);
    axios
      .post(this.s3.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
        // onUploadProgress: progressEvent => {
        //   console.log(progressEvent.loaded / progressEvent.total);
        // }
      })
      .then(() => {
        if (success) success(true);
      })
      .catch(() => {
        if (fail) fail();
        this.showErrorMessage();
      })
      .finally(() => {
        if (done) done();
        if (this.notify) this.notify(false);
      });
  }

  requestAWS() {
    let awsUrl = `${APP_CONFIG.baseAPIURL}/aws/`;
    axios
      .get(awsUrl, { params: { app_name: this.awsDirname }})
      .then(response => {
        let data = JSON.parse(JSON.stringify(response.data));
        this.s3 = data.s3;
      })
      .catch(() => {
        // perlu berikan pesan error toast atau snackbar
        // console.log(error);
      });
  }
}

export default UploadPhoto;
