import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "ujian";
const modelPath = `/${modelName}/`;

class Ujian extends ModelBase {
  constructor() {
    let model = {
      id: null,
      judul: "",
      pspd: "",
      tanggal_ujian: "",
      mengulang: null,
      rencana_ujian: "",
      jenis: null,
      stase: {id: null, nama: ""},
      penguji_set: [],
      dokumenujian_set: [],
      hasil: "",
      verifikasi: "",
      nilai: null,
      nilai_sikap: null,
      foto: null,
      extra_data: {}
    };
    super(modelName, model, [], ["hasil", "nilai", "nilai_sikap"], modelPath);
  }

  getLoadData(data) {
    let ujian = super.getLoadData(data);
    ujian.jenis = ujian.jenis.nama;
    let nilai = ujian['nilai'];
    let nilai_sikap = ujian['nilai_sikap'];
    ujian['nilai'] = nilai? parseFloat(nilai):nilai;
    ujian['nilai_sikap'] = nilai_sikap? parseFloat(nilai_sikap):nilai_sikap;
    return ujian;
  }

  getPayload() {
    let ujian = this.observables.ujian;
    let ver = ujian.nilai != null && ujian.hasil != "Belum" ? "Ya" : "Belum";
    this.observables.ujian.verifikasi = ver;
    let data = {nilai_sikap: ujian.nilai_sikap, nilai: ujian.nilai, hasil: ujian.hasil, verifikasi: ver};
    return data;
  }
}

export default Ujian;
